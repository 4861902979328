.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg) !important;
}
.MuiExpansionPanelSummary-expandIcon {
  order: -1;
  transform: rotate(-90deg) !important;
  margin-right: 0px !important;
}

.MuiExpansionPanelSummary-content {
  align-items: center;
}

.MuiExpansionPanelSummary-root {
  padding: 0 24px 0 0px !important;
}
